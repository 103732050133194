import { DocumentType, TaskType } from '@innedit/innedit-type';
import { TaskData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';

import { ListItemProps } from '~/containers/Espace/List/props';
import IconFlag from '~/icons/Flag';
import IconPlus from '~/icons/Plus';
import { colors } from '~/styles/theme';

import ListItem from './index';

const ListItemTask: FC<ListItemProps<TaskType, TaskData>> = ({
  className,
  docId,
  index,
  onClick,
  model,
}) => {
  const [doc, setDoc] = useState<DocumentType<TaskType>>();

  useEffect(() => {
    let isMounted = true;
    const unsub = model.watchById(docId, document => {
      if (isMounted) {
        setDoc(document);
      }
    });

    return () => {
      isMounted = false;
      if (unsub) {
        unsub();
      }
    };
  }, [docId, model]);

  if (!doc) {
    return null;
  }

  let priorityColor: string;
  switch (doc.priority) {
    case 'urgent': {
      // eslint-disable-next-line prefer-destructuring
      priorityColor = colors.danger[500];
      break;
    }
    case 'high': {
      // eslint-disable-next-line prefer-destructuring
      priorityColor = colors.warning[500];
      break;
    }

    case 'normal': {
      // eslint-disable-next-line prefer-destructuring
      priorityColor = colors.info[400];
      break;
    }

    case 'low': {
      // eslint-disable-next-line prefer-destructuring
      priorityColor = colors.neutral[300];
      break;
    }
    default:
      // eslint-disable-next-line prefer-destructuring
      priorityColor = colors.light[600];
  }

  return (
    <ListItem
      actions={[
        {
          icon: IconFlag,
          iconColor: priorityColor,
          label: 'Changer la priorité',
        },
        {
          icon: IconPlus,
          label: 'Ajouter une sous-page',
          to: `/espaces/${doc.espaceId}/tasks/create?parent=${docId}`,
        },
      ]}
      className={className}
      date={doc.deadline}
      description={doc.favorableOutcome}
      displayActionId={false}
      doc={doc}
      index={index}
      label={doc.label}
      onClick={onClick}
    />
  );
};

export default ListItemTask;
